import { useStore } from "vuex";
import { random, map } from '../utils/utils.js';

export default class Canvas_Manager {
    constructor(args) {
        // VueX
        this.store = useStore();

        // Parameters
        this.parentContainer = args.parentContainer;
        this.animationFunctionPrototype = args.animationFunction;
        this.animationHandler = null;
        this.animationLoop = null; // reference to requestAnimationFrame function
        this.resizing = false;

        // If there is a font argument, preload that font - otherwise, just init the manager
        // DO: Preload all fonts
        if (args.font) { this.loadFont(args.font).then((loadedFont) => { this.init(); }); } else { this.init(); }
    }




    // INIT ---------------------------------------------------------------------------------------------
    init() {
        this.setup();
    }



    // SETUP ---------------------------------------------------------------------------------------------
    setup() {
        // Create canvas element
        this.createCanvas();

        // Bind resize event
        this.resizeFunction = () => { this.resize() };
        window.addEventListener("resize", this.resizeFunction);

        // Resize to determine correct dimensions
        this.resize();

        // Initialize the animation function object, based on the prototype that was passed in
        this.animationFunction = new this.animationFunctionPrototype();

        // Start the animation loop
        this.startAnimationLoop();
    }


    // DESTROY ---------------------------------------------------------------------------------------------
    destroy() {
        // Destroy animation loop and reference
        this.stopAnimationLoop();

        // Unbind resize event
        window.removeEventListener("resize", this.resizeFunction);

    }


    // CREATE INTERNAL CANVAS ---------------------------------------------------------------------------------------------
    createCanvas() {
        // Create Canvas
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.parentContainer.clientWidth;
        this.canvas.height = this.parentContainer.clientHeight;

        // Get context
        this.ctx = this.canvas.getContext("2d");

        // Push to DOM
        this.parentContainer.append(this.canvas);

        // Send data to VueX
        this.store.dispatch("updateState", { parent: "canvas", key: "canvas", value: this.canvas });
        this.store.dispatch("updateState", { parent: "canvas", key: "ctx", value: this.ctx, });

    }


    // RESIZE ---------------------------------------------------------------------------------------------
    resize() {
        return new Promise(resolve => {
            this.resizing = true;

            // Get the device pixel ratio, falling back to 1 & and update the store with a boolean referencing the retina state
            this.dpr = window.devicePixelRatio || 1;
            this.store.dispatch("updateState", { parent: "canvas", key: "retina", value: this.dpr == 1 ? false : true });
            this.store.dispatch("updateState", { parent: "canvas", key: "dpr", value: this.dpr });

            // Calculate new dimensions based on the parent container width
            let dimensions = {
                width: this.parentContainer.clientWidth,
                height: this.parentContainer.clientHeight
            }

            // Dispatch new dimensions to store and updated internal variables to main canvas
            this.store.dispatch("updateState", { parent: "canvas", key: "width", value: dimensions.width * this.dpr * this.store.state.canvas.scale });
            this.store.dispatch("updateState", { parent: "canvas", key: "height", value: dimensions.height * this.dpr * this.store.state.canvas.scale });
            this.width = this.store.state.canvas.width;
            this.height = this.store.state.canvas.height;

            // Set size and scale
            this.canvas.width = this.width;
            this.canvas.height = this.height;

            // Retina scaling
            this.canvas.style.width = "100%";
            this.canvas.style.height = "auto";
            this.canvas.getContext('2d').scale(this.dpr, this.dpr)

            // Finish up and resolve
            this.resizing = false;
            resolve('Resize event complete');
        });
    }



    // LOAD A FONT, PROMISE BASED ---------------------------------------------------------------------------------------------
    loadFont(pathToUrl) {
        return new Promise(resolve => {
            // Define font to load
            let fontToLoad = new FontFace('ActiveFont', 'url(' + pathToUrl + ')');

            // Load that font
            fontToLoad.load().then(function (loaded_fontFace) {
                document.fonts.add(loaded_fontFace);
                resolve(loaded_fontFace);
            }).catch(function (error) {
                console.error('Error loading font: ' + error);
            });
        });
    }



    // START ANIMATION LOOP ---------------------------------------------------------------------------------------------

    startAnimationLoop() {
        // let animFrame = new AnimationFrame(60, this.animate, this);
        // animFrame.start();

        this.animationHandler = this.animate.bind(this);
        this.animate();
    }



    // STOP ANIMATION LOOP ---------------------------------------------------------------------------------------------
    stopAnimationLoop() {
        cancelAnimationFrame(this.animationLoop);
        this.animationHandler = null;
    }



    // ANIMATION HANDLER ---------------------------------------------------------------------------------------------
    animate(delta, reference) {
        // reference.render();

        this.render();
        this.animationLoop = requestAnimationFrame(this.animationHandler);
    }



    // RENDER LOOP LOGIC ---------------------------------------------------------------------------------------------
    render() {
        this.update();
        this.draw();
    }



    // UPDATE LOGIC ---------------------------------------------------------------------------------------------
    update() {
        // Stats
        if (window.stats != null) window.stats.begin();

        // FrameCount
        this.store.dispatch("animation_increaseFrameCount");
    }



    // DRAW LOGIC ---------------------------------------------------------------------------------------------
    draw() {
        // Only execute this if there is no resize event happening
        if (!this.resizing) {
            // Trigger animation function
            this.animationFunction.update();
        }

        // Stats
        if (window.stats != null) window.stats.end();
    }



    // DOWNLOAD THE CANVAS AS A PNG ---------------------------------------------------------------------------------------------
    downloadCanvas(scale) {
        // Resize to desired scale
        const originalScale = this.store.state.canvas.scale;
        this.stopAnimationLoop();
        this.store.dispatch("updateState", { parent: "canvas", key: "scale", value: scale });
        this.resize().then(() => {
            // Draw high res image
            this.draw();

            // Create graphic at desired scale
            let downloadLink = document.createElement('a');
            downloadLink.download = 'CanvasExport.png';
            downloadLink.href = this.canvas.toDataURL("image/png");
            downloadLink.click();

            // Return to original scale
            this.store.dispatch("updateState", { parent: "canvas", key: "scale", value: originalScale });
            this.resize();
            this.startAnimationLoop();
        });
    }



    // FONT SIZE CALCULATIONS ---------------------------------------------------------------------------------------------
    getFontWidth(ctx, text) {
        let actualWidth = ctx.measureText(text).width;
        return actualWidth;
    }
    getFontHeight(ctx, text) {
        // let actualHeight = ctx.measureText(text).actualBoundingBoxDescent / 2;
        let metrics = ctx.measureText(text);
        let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        return actualHeight;
    }


}

