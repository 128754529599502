<template>
    <div id="sketchCanvas" ref="sketchCanvas"></div>
</template>


<script>
// Vue
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-dom";

// CanvasManager
import Canvas_Manager from "@/managers/CanvasManager.js";
let canvasManager = ref(null); // reference to the manager
export { canvasManager }; // export manager-reference to expose it to other components

// Specific animation object
import sketch_Animation from './BoilerPlate.js';

export default {
    name: "Boilerplate",

    setup() {
        const store = useStore();
        const sketchCanvas = ref(null);

        onMounted(() => {
            // Initialize CanvasManager
            canvasManager = new Canvas_Manager({
                parentContainer: sketchCanvas.value,
                animationFunction: sketch_Animation,
            });
        });

        onUnmounted(() => {
            // Destroy CanvasManager
            canvasManager.destroy();
            canvasManager = ref(null);
        });

        return { sketchCanvas };
    }
}

</script>

<style lang="scss" scoped>
#sketchCanvas {
    width: 100vw;
    height: 100vh;
}
</style>
