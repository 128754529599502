import { useStore } from "vuex";

export default class sketch_Animation {
    constructor(args) {
        // VueX
        this.store = useStore();

        // Initialize 
        this.init();
    }

    
    
    // INIT ---------------------------------------------------------------------------------------------
    init() {
        this.canvas = this.store.state.canvas.canvas;
        this.ctx = this.store.state.canvas.ctx;
    }



    // UPDATE ---------------------------------------------------------------------------------------------
    update() {
        this.width = this.store.state.canvas.width;
        this.height = this.store.state.canvas.height;
        this.colors = this.store.state.colors;

        this.draw();
    }


    // DRAW ---------------------------------------------------------------------------------------------
    draw() {
        this.ctx.beginPath();
        this.ctx.fillStyle = this.colors.backgroundColor;
        this.ctx.rect(0, 0, this.width, this.height);
        this.ctx.fill();

        this.ctx.fillStyle = this.colors.textColor;
        this.ctx.font = this.width * 0.15 + "px Roboto";
        this.ctx.fillText("Hello World", 30, this.height*0.5);
    }
}


